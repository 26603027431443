<template>
<div class="ztnrbg">


	<div class="index_mv ztnr">
		<leftMeun  :name='name' :menuid='id'></leftMeun>
		<div class="right_nr">
			<rightMeun :name='name' :menuid='id'></rightMeun>	
	<div class="form category_news_article" style="border: 0;">
		<Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-position="windowW>=576?'right':'top'"
		 :label-width="windowW>=576?170:null">
			<FormItem label="单位名称" prop="company">
				<Input v-model="formValidate.company" placeholder="请输入单位名称" />
			</FormItem>
			<FormItem label="姓名" prop="name">
				<Input v-model="formValidate.name" placeholder="请输入姓名" />
			</FormItem>
			<FormItem label="电话" prop="tel">
				<Input type='tel' maxlength='11' v-model="formValidate.tel" placeholder="请输入电话" />
			</FormItem>
			<FormItem label="档案需求原因及用途" prop="reasons">
				<Input v-model="formValidate.reasons" type="textarea" :autosize="{minRows: 5,maxRows: 5}" placeholder="请输入档案需求原因及用途" />
			</FormItem>
			<FormItem label="所需档案内容" prop="content">
				<Input v-model="formValidate.content" type="textarea" :autosize="{minRows: 5,maxRows: 5}" placeholder="请输入所需档案内容" />
			</FormItem>
			<FormItem label="预约时间" class='times' prop="gettime">
				<DatePicker type="date" placeholder="选择预约时间" v-model="formValidate.gettime"></DatePicker>
			</FormItem>
			<FormItem label="归还时间" class='times' prop="returntime">
				<DatePicker type="date" placeholder="选择归还时间" v-model="formValidate.returntime"></DatePicker>
			</FormItem>
			<FormItem label="查档须知：">
				综合档案室查档流程图 <a class="ivu-btn ivu-btn-primary" style="display: inline-block;" href="/lct.pdf" download="综合档案室查档流程图.pdf">下载</a>
				<p style="padding: 8px 0;">
					南方科技大学档案服务利用细则 <a class="ivu-btn ivu-btn-primary" style="display: inline-block;" href="/lct1.docx" download="南方科技大学档案服务利用细则.docx">下载</a>
				</p>
				<p>
					南方科技大学档案分类方案（征求意见稿）<a class="ivu-btn ivu-btn-primary" style="display: inline-block;" href="/lct3.pdf" download="南方科技大学档案分类方案（征求意见稿）.pdf">下载</a>
				</p>
			</FormItem>
			<!-- <div class="ts">
				请下载 南方科技大学综合档案室档案利用流程图
			</div> -->
			<FormItem>
				<Button type="primary" @click="handleSubmit('formValidate')">提交</Button>
				<Button @click="handleReset('formValidate')" style="margin-left: 8px">重置</Button>
			</FormItem>
		</Form>

	</div>
</div>
<newRightMeun></newRightMeun>
	</div>
</div>
</template>

<script>
	import leftMeun from '@/components/leftMeun.vue'
	import rightMeun from '@/components/rightMeun.vue'
        import newRightMeun from '@/components/newRightMeun.vue'

		export default {
			components: {
				leftMeun,
				rightMeun,
                 newRightMeun
			},
			data() {
				return {
					id:this.$route.query.pid,
					name:'',
					formValidate: {
						tel: "",
						company: '',
						content: "",
						gettime: '',
						returntime: "",
						name: "",
						reasons: ''
					},
					ruleValidate: {
						tel: [{
							required: true,
							message: '手机号不能为空',
							trigger: 'blur'
						}],
						company: [{
							required: true,
							message: '请输入单位名称',
							trigger: 'blur'
						}],
						content: [{
							required: true,
							message: '请输入所需档案内容',
							trigger: 'blur'
						}],
						gettime: [{
							required: true,
							type: 'date',
							message: '选择预约时间',
							trigger: 'blur'
						}],
						returntime: [{
							required: true,
							type: 'date',
							message: '选择归还时间',
							trigger: 'blur'
						}],
						name: [{
							required: true,
							message: '请输入姓名',
							trigger: 'blur'
						}],

						reasons: [{
							required: true,
							message: '请输入档案需求原因及用途',
							trigger: 'blur'
						}]
					}
				}
			},
			created() {
				var that = this
				that.$api.menus({
					fid: 0
				}).then(res => {
					that.name= res.data[that.$route.query.index].title
				}).catch((error) => {
					// error
					console.log(error)
				})
				that.$emit('getLoad', true);
			},
			methods: {

				handleSubmit(name) {
					var that = this
					if (that.formValidate.gettime.toString().indexOf('-') != -1) {
						that.formValidate.gettime = new Date(that.formValidate.gettime)
					}
					if (that.formValidate.returntime.toString().indexOf('-') != -1) {
						that.formValidate.returntime = new Date(that.formValidate.returntime)
					}
					console.log(that.formValidate.gettime, that.formValidate.returntime)
					that.$refs[name].validate((valid) => {
						console.log(valid)
						if (valid) {
							if (that.formValidate.gettime.toString().indexOf('-') == -1) {
								if (that.formValidate.gettime) {
									var gettime = that.formValidate.gettime
									that.formValidate.gettime = gettime.getFullYear() + '-' + (gettime.getMonth() + 1) + '-' + gettime.getDate()
								}

							}
							if (that.formValidate.returntime.toString().indexOf('-') == -1) {
								if (that.formValidate.returntime) {
									var returntime = that.formValidate.returntime
									that.formValidate.returntime = returntime.getFullYear() + '-' + (returntime.getMonth() + 1) + '-' +
										returntime.getDate()
								}

							}


							that.$api.signup(that.formValidate).then(res => {
								if (res.code == 1) {
									that.$Message.success(res.msg);
								} else {
									that.$Message.error(res.msg);
								}
								console.log(res)
							}).catch((error) => {
								// error

								console.log(error)
							})

							console.log(valid)

						} else {
							that.$Message.error('请把信息填写完全！');
						}
					})
				},
				handleReset(name) {
					this.$refs[name].resetFields();
				}
			}
		}
</script>

<style scoped="scoped">
	.category_news_article p{
		clear: both;
		overflow: hidden;
	}
	.category_news_article a{
		float: right;
	}
</style>
